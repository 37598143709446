/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/starkey/halo/'], ['en', 'https://www.hear.com/hearing-aids/brands/starkey/halo/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/starkey/halo/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/starkey/halo/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "connect-apple-devices-to-the-starkey-halo",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#connect-apple-devices-to-the-starkey-halo",
    "aria-label": "connect apple devices to the starkey halo permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Connect Apple Devices to the Starkey Halo"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We rely on technology everyday by texting friends and family with our mobile phones, streaming movies on our iPads, watching movies on our computers and televisions, and much more! We listen to conversations through Bluetooth® earpieces and search for restaurants by using voice recognition with our electronic devices. Hearing aids today have advanced technology to make your life more simple. The Starkey Halo hearing aids stream directly from your iPhone, iPad and Apple Watch to deliver clear sounds and create a convenient listening experience."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With the Starkey Halo, the possibilities sound wonderful!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "stream-directly-to-your-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#stream-directly-to-your-hearing-aids",
    "aria-label": "stream directly to your hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Stream Directly to Your Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you wear hearing aids, you can’t use headphones to listen to music. However, your Starkey Halo hearing aids can act as your personal set of wireless earphones by streaming audio from your iPhone or iPad."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Personalized sound experience")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Starkey Halo hearing aids are customized to address your hearing needs. Whether you need to amplify high or low frequencies, the Starkey Halo hearing aids accommodate to your degree of hearing loss. You will not only hear everything clearly, but will also enjoy a high-quality sound experience."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Freedom")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Now that you can listen to your favorite music with your hearing aids, you don’t have to ask anyone in your home what they want to hear. Simply turn on your music and begin listening without bothering anyone else. You can listen to rock and roll, classical, new age – whatever your heart fancies!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Stream phone conversations")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Being able to have clear phone conversations is more important than ever as family members often live far apart from each other. Older hearing aids made a whistling sound as they picked up sound from a receiver. The Starkey Halos are built with technology to prevent whistling. The feedback canceler ensures there is no whistling during phone calls. You will have clear conversations once again!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Stream audio directly from your iPad")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Starkey Halo offers a quality sound experience while making your life more convenient. Whether you are watching the video of your granddaughter singing in her school play or are watching a viral YouTube video, you can hear crisp sounds delivered right to your hearing aids. You don’t have to worry about interference from sounds inside or outside of your home."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Starkey Halos are practical because you can watch a movie without bothering others. Let’s say you want to watch a movie while your partner is doing paperwork at home. You can download a movie from iTunes, get comfy on the couch and quietly listen to a movie streamed directly to the Starkey Halo hearing aids from the iPad."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "important-technological-features",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#important-technological-features",
    "aria-label": "important technological features permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Important Technological Features"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Noise reduction")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The noise reduction technology in Starkey Halo hearing aids reduce background noises in loud environments like crowded stadiums, malls, parks, corporate meetings and more."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Feedback canceler")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The feedback canceler ensures there is no whistling sound when someone speaks softly."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Directional microphone")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The directional microphone focuses on the person speaking to you. This allows the hearing aid to amplify the speaker’s voice while the noise reduction technology reduces background noise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Patented high-frequency technology")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Starkey Halo picks up high-frequency sounds and then replicates them in lower frequency range so they are easier to hear. High-frequency hearing loss is one of the most common types of hearing loss. People often have difficulty hearing women’s and children’s voices and the Starkey Halo helps to solve this problem."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "control-application-for-the-iphone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#control-application-for-the-iphone",
    "aria-label": "control application for the iphone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Control Application for the iPhone"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Starkey Halo comes with the TruLink application for the iPhone. The TruLink application lets you control your hearing aids and stream conversations, music and more from your iPhone and iPad. The interface is easy to read and navigation is intuitive."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Settings adjustment")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The TruLink app gives you maximum control to find the settings that work best for you. In the past, you would have to go back to the audiologist to adjust the hearing aid settings. Now, you can work with your audiologist to find the best settings, but then tweak these settings on your own as you experience sounds in different environments. You can continue to adjust the hearing aid settings until you find the best results. You can program and save the settings for specific environments and locations with TruLink Memory. You can even create a geographic tag for a location using the satellite map in the application. The application will recognize when you return to that location and automatically adjust the hearing aid settings to match your stored specifications."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Sound adjustment")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The TruLink application lets you adjust the volume on the hearing aids using a horizontal swipe bar. The right and left hearing aids can be adjusted separately. Adjusting the volume using the iPhone app is much easier than adjusting the volume on the hearing aid itself."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Locate your hearing aids")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While people do their best to take care of their hearing aids, accidents do happen and people misplace their hearing aids. Fortunately, the Starkey Halo TruLink app has a locator feature called Find My Hearing Aids. This app shows a location and helps you find the hearing aids with a signal that gets weaker or stronger based on how close you are to the hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Hearing aid specifications")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Starkey Halo is made for people with mild to severe hearing loss. These hearing aids are discreet, behind the ear hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearcom-risk-free-trial",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearcom-risk-free-trial",
    "aria-label": "hearcom risk free trial permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "hear.com Risk-Free Trial"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we are hearing experts who understand the technological features of hearing aids from the top eight manufacturers. We can help you learn how Widex, Oticon, and Resound hearing aids compare to Starkey Halo hearing aids. During a phone consultation, we will discuss your lifestyle and which hearing aids and accessories would best match your needs. We do this by discussing the activities you are involved in to see which accessories you need for various activities. For instance, if you watch TV a lot you would benefit from a TV streamer accessory. If you use your iPhone or Android phone frequently, then you would benefit from a mobile phone streamer or from a hearing aid that connects directly to an iPhone."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "During a phone consultation, we would also discuss the features that produce clear sounds and reduce background noise. For a thorough consultation, please call us today! We look forward to helping you on your journey toward better hearing!"), "\n", React.createElement(ButtonCta, {
    copy: "Try the Starkey Halo now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
